<template>
 <svg width="338" height="319" viewBox="0 0 338 319" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M146.125 0.0419312C142.001 0.041915 138.046 1.67997 135.131 4.59574C132.215 7.51151 130.577 11.4662 130.577 15.5897C130.577 19.7132 132.215 23.6679 135.13 26.5837C138.046 29.4995 142.001 31.1376 146.124 31.1376C150.248 31.1376 154.203 29.4995 157.119 26.5837C160.034 23.6679 161.672 19.7133 161.672 15.5898C161.672 11.4662 160.034 7.51156 157.119 4.59578C154.203 1.68 150.248 0.0419312 146.125 0.0419312Z" fill="#E6E6E6"/>
<path d="M259.011 28.7083H177.385C176.354 28.7083 175.365 28.2988 174.636 27.5698C173.907 26.8409 173.498 25.8522 173.498 24.8213C173.498 23.7905 173.907 22.8018 174.636 22.0729C175.365 21.3439 176.354 20.9344 177.385 20.9344H259.011C260.042 20.9344 261.03 21.3439 261.759 22.0729C262.488 22.8018 262.898 23.7905 262.898 24.8213C262.898 25.8522 262.488 26.8409 261.759 27.5698C261.03 28.2988 260.042 28.7083 259.011 28.7083Z" fill="#E6E6E6"/>
<path d="M203.136 12.1888H177.385C176.354 12.1888 175.365 11.7793 174.636 11.0503C173.907 10.3214 173.498 9.3327 173.498 8.30181C173.498 7.27093 173.907 6.28227 174.636 5.55332C175.365 4.82438 176.354 4.41486 177.385 4.41486H203.136C204.167 4.41486 205.155 4.82438 205.884 5.55332C206.613 6.28227 207.023 7.27093 207.023 8.30181C207.023 9.3327 206.613 10.3214 205.884 11.0503C205.155 11.7793 204.167 12.1888 203.136 12.1888Z" fill="#E6E6E6"/>
<path d="M146.125 65.1484C142.001 65.1484 138.046 66.7865 135.131 69.7022C132.215 72.618 130.577 76.5727 130.577 80.6962C130.577 84.8197 132.215 88.7744 135.13 91.6902C138.046 94.606 142.001 96.2441 146.124 96.2441C150.248 96.2441 154.203 94.606 157.119 91.6902C160.034 88.7745 161.672 84.8198 161.672 80.6963C161.672 76.5727 160.034 72.6181 157.119 69.7023C154.203 66.7865 150.248 65.1484 146.125 65.1484Z" fill="#E6E6E6"/>
<path d="M259.011 93.8149H177.385C176.354 93.8149 175.365 93.4054 174.636 92.6764C173.907 91.9475 173.498 90.9588 173.498 89.9279C173.498 88.897 173.907 87.9084 174.636 87.1794C175.365 86.4505 176.354 86.041 177.385 86.041H259.011C260.042 86.041 261.03 86.4505 261.759 87.1794C262.488 87.9084 262.898 88.897 262.898 89.9279C262.898 90.9588 262.488 91.9475 261.759 92.6764C261.03 93.4054 260.042 93.8149 259.011 93.8149Z" fill="#E6E6E6"/>
<path d="M203.136 77.2953H177.385C176.354 77.2953 175.365 76.8858 174.636 76.1568C173.907 75.4279 173.498 74.4392 173.498 73.4083C173.498 72.3774 173.907 71.3888 174.636 70.6598C175.365 69.9309 176.354 69.5214 177.385 69.5214H203.136C204.167 69.5214 205.155 69.9309 205.884 70.6598C206.613 71.3888 207.023 72.3774 207.023 73.4083C207.023 74.4392 206.613 75.4279 205.884 76.1568C205.155 76.8858 204.167 77.2953 203.136 77.2953Z" fill="#E6E6E6"/>
<path d="M146.125 195.362C142.001 195.361 138.046 197 135.131 199.915C132.215 202.831 130.577 206.786 130.577 210.909C130.577 215.033 132.215 218.987 135.13 221.903C138.046 224.819 142.001 226.457 146.124 226.457C150.248 226.457 154.203 224.819 157.119 221.903C160.034 218.988 161.672 215.033 161.672 210.909C161.672 206.786 160.034 202.831 157.119 199.915C154.203 197 150.248 195.362 146.125 195.362Z" fill="#E6E6E6"/>
<path d="M259.011 224.028H177.385C176.354 224.028 175.365 223.618 174.636 222.889C173.907 222.16 173.498 221.172 173.498 220.141C173.498 219.11 173.907 218.121 174.636 217.392C175.365 216.663 176.354 216.254 177.385 216.254H259.011C260.042 216.254 261.03 216.663 261.759 217.392C262.488 218.121 262.898 219.11 262.898 220.141C262.898 221.172 262.488 222.16 261.759 222.889C261.03 223.618 260.042 224.028 259.011 224.028Z" fill="#E6E6E6"/>
<path d="M203.136 207.508H177.385C176.354 207.508 175.365 207.099 174.636 206.37C173.907 205.641 173.498 204.652 173.498 203.621C173.498 202.591 173.907 201.602 174.636 200.873C175.365 200.144 176.354 199.734 177.385 199.734H203.136C204.167 199.734 205.155 200.144 205.884 200.873C206.613 201.602 207.023 202.591 207.023 203.621C207.023 204.652 206.613 205.641 205.884 206.37C205.155 207.099 204.167 207.508 203.136 207.508Z" fill="#E6E6E6"/>
<path d="M146.125 260.468C142.001 260.468 138.046 262.106 135.131 265.022C132.215 267.938 130.577 271.892 130.577 276.016C130.577 280.139 132.215 284.094 135.13 287.01C138.046 289.926 142.001 291.564 146.124 291.564C150.248 291.564 154.203 289.926 157.119 287.01C160.034 284.094 161.672 280.139 161.672 276.016C161.672 271.892 160.034 267.938 157.119 265.022C154.203 262.106 150.248 260.468 146.125 260.468Z" fill="#E6E6E6"/>
<path d="M259.011 289.134H177.385C176.354 289.134 175.365 288.725 174.636 287.996C173.907 287.267 173.498 286.278 173.498 285.247C173.498 284.217 173.907 283.228 174.636 282.499C175.365 281.77 176.354 281.361 177.385 281.361H259.011C260.042 281.361 261.03 281.77 261.759 282.499C262.488 283.228 262.898 284.217 262.898 285.247C262.898 286.278 262.488 287.267 261.759 287.996C261.03 288.725 260.042 289.134 259.011 289.134Z" fill="#E6E6E6"/>
<path d="M203.136 272.615H177.385C176.354 272.615 175.365 272.205 174.636 271.476C173.907 270.747 173.498 269.759 173.498 268.728C173.498 267.697 173.907 266.708 174.636 265.979C175.365 265.25 176.354 264.841 177.385 264.841H203.136C204.167 264.841 205.155 265.25 205.884 265.979C206.613 266.708 207.023 267.697 207.023 268.728C207.023 269.759 206.613 270.747 205.884 271.476C205.155 272.205 204.167 272.615 203.136 272.615Z" fill="#E6E6E6"/>
<path d="M290.51 183.215H102.964C100.839 183.212 98.8012 182.367 97.2982 180.864C95.7953 179.361 94.9499 177.323 94.9475 175.198V114.95C94.9499 112.825 95.7953 110.787 97.2983 109.284C98.8012 107.781 100.839 106.936 102.964 106.933H290.51C292.635 106.936 294.673 107.781 296.176 109.284C297.679 110.787 298.524 112.825 298.527 114.95V175.198C298.524 177.323 297.679 179.361 296.176 180.864C294.673 182.367 292.635 183.212 290.51 183.215Z" fill="#2F2E41"/>
<path d="M125.996 167.291C121.602 167.291 117.307 165.988 113.653 163.547C110 161.106 107.152 157.636 105.47 153.576C103.789 149.517 103.349 145.049 104.206 140.74C105.063 136.43 107.179 132.471 110.286 129.364C113.394 126.257 117.352 124.141 121.662 123.284C125.972 122.427 130.439 122.867 134.498 124.548C138.558 126.23 142.028 129.078 144.469 132.731C146.91 136.385 148.213 140.68 148.213 145.074C148.206 150.964 145.864 156.611 141.699 160.776C137.534 164.942 131.886 167.284 125.996 167.291ZM125.996 123.829C121.794 123.829 117.687 125.075 114.193 127.409C110.699 129.744 107.976 133.062 106.368 136.944C104.76 140.826 104.34 145.098 105.159 149.219C105.979 153.34 108.002 157.126 110.974 160.097C113.945 163.068 117.73 165.091 121.851 165.911C125.973 166.731 130.244 166.31 134.126 164.702C138.008 163.094 141.326 160.371 143.661 156.877C145.995 153.384 147.241 149.276 147.241 145.074C147.235 139.442 144.995 134.041 141.012 130.059C137.029 126.076 131.629 123.835 125.996 123.829Z" fill="#3F3D56"/>
<path d="M283.777 163.41H169.688C168.974 163.411 168.267 163.271 167.608 162.998C166.948 162.725 166.348 162.325 165.843 161.82C165.338 161.316 164.938 160.717 164.664 160.057C164.391 159.398 164.25 158.691 164.25 157.977C164.25 157.263 164.391 156.556 164.664 155.897C164.938 155.237 165.338 154.638 165.843 154.134C166.348 153.629 166.948 153.229 167.608 152.956C168.267 152.684 168.974 152.544 169.688 152.544H283.777C284.49 152.544 285.197 152.684 285.857 152.956C286.517 153.229 287.116 153.629 287.621 154.134C288.126 154.638 288.527 155.237 288.8 155.897C289.074 156.556 289.214 157.263 289.214 157.977C289.214 158.691 289.074 159.398 288.8 160.057C288.527 160.717 288.126 161.316 287.621 161.82C287.116 162.325 286.517 162.725 285.857 162.998C285.197 163.271 284.49 163.411 283.777 163.41Z" fill="#93C5FD"/>
<path d="M205.68 140.321H169.688C168.974 140.321 168.267 140.181 167.608 139.908C166.948 139.636 166.348 139.236 165.843 138.731C165.338 138.227 164.938 137.627 164.664 136.968C164.391 136.309 164.25 135.602 164.25 134.888C164.25 134.174 164.391 133.467 164.664 132.808C164.938 132.148 165.338 131.549 165.843 131.044C166.348 130.54 166.948 130.14 167.608 129.867C168.267 129.594 168.974 129.454 169.688 129.455H205.68C206.394 129.454 207.101 129.594 207.761 129.867C208.421 130.14 209.02 130.54 209.525 131.044C210.03 131.549 210.431 132.148 210.704 132.808C210.978 133.467 211.118 134.174 211.118 134.888C211.118 135.602 210.978 136.309 210.704 136.968C210.431 137.627 210.03 138.227 209.525 138.731C209.02 139.236 208.421 139.636 207.761 139.908C207.101 140.181 206.394 140.321 205.68 140.321Z" fill="#93C5FD"/>
<path d="M290.51 183.215H102.964C100.839 183.212 98.8012 182.367 97.2982 180.864C95.7953 179.361 94.9499 177.323 94.9475 175.198V114.95C94.9499 112.825 95.7953 110.787 97.2983 109.284C98.8012 107.781 100.839 106.936 102.964 106.933H290.51C292.635 106.936 294.673 107.781 296.176 109.284C297.679 110.787 298.524 112.825 298.527 114.95V175.198C298.524 177.323 297.679 179.361 296.176 180.864C294.673 182.367 292.635 183.212 290.51 183.215ZM102.964 107.419C100.968 107.421 99.0535 108.216 97.6416 109.627C96.2298 111.039 95.4356 112.954 95.4334 114.95V175.198C95.4356 177.195 96.2298 179.109 97.6416 180.521C99.0535 181.933 100.968 182.727 102.964 182.729H290.51C292.507 182.727 294.421 181.933 295.833 180.521C297.245 179.109 298.039 177.195 298.041 175.198V114.95C298.039 112.954 297.245 111.039 295.833 109.627C294.421 108.216 292.507 107.421 290.51 107.419H102.964Z" fill="#3F3D56"/>
<path d="M12.356 213.346C12.1311 212.616 12.0687 211.847 12.1733 211.091C12.278 210.335 12.5471 209.611 12.9617 208.971C13.3762 208.33 13.9262 207.788 14.5729 207.383C15.2196 206.978 15.9473 206.72 16.7046 206.626L41.6457 177.482L47.4629 183.59L22.62 211.534C22.6926 212.817 22.2921 214.082 21.4946 215.089C20.697 216.096 19.5577 216.776 18.2925 216.999C17.0274 217.222 15.7243 216.973 14.6301 216.3C13.536 215.627 12.7269 214.576 12.356 213.346Z" fill="#FFB7B7"/>
<path d="M33.5316 182.033C33.5061 181.72 33.5485 181.405 33.6559 181.11C33.7632 180.814 33.933 180.546 34.1537 180.322L41.859 172.509C42.807 171.21 44.2315 170.34 45.82 170.09C47.4085 169.84 49.0312 170.231 50.3323 171.176C51.6333 172.121 52.5064 173.543 52.76 175.131C53.0135 176.719 52.6268 178.343 51.6848 179.646L46.5615 189.39C46.4152 189.668 46.2108 189.912 45.962 190.104C45.7133 190.295 45.4261 190.432 45.12 190.503C44.8139 190.573 44.4962 190.578 44.1883 190.515C43.8805 190.452 43.5899 190.323 43.3362 190.138L34.4208 183.622C34.167 183.437 33.9562 183.199 33.8027 182.925C33.6493 182.65 33.5568 182.346 33.5316 182.033Z" fill="#63B4FF"/>
<path d="M51.6244 164.151C58.215 164.151 63.5578 158.808 63.5578 152.218C63.5578 145.627 58.215 140.284 51.6244 140.284C45.0337 140.284 39.6909 145.627 39.6909 152.218C39.6909 158.808 45.0337 164.151 51.6244 164.151Z" fill="#FFB7B7"/>
<path d="M45.1358 205.618C44.8986 205.442 44.7 205.219 44.5525 204.963C44.4049 204.707 44.3114 204.423 44.2778 204.129C43.4528 197.233 42.2522 190.387 40.6812 183.621C39.9857 180.581 40.4177 177.391 41.8968 174.646C43.3759 171.9 45.8016 169.784 48.723 168.693C51.6445 167.601 54.863 167.607 57.7803 168.71C60.6977 169.812 63.1155 171.937 64.5843 174.688C69.2774 183.496 69.6359 195.588 69.5722 200.379C69.565 200.89 69.3785 201.382 69.0452 201.77C68.712 202.158 68.2532 202.417 67.7488 202.501L46.8102 206.025C46.6882 206.046 46.5646 206.056 46.4408 206.056C45.9699 206.056 45.5117 205.902 45.1358 205.618Z" fill="#2F2E41"/>
<path d="M90.3408 166.763C90.2811 167.025 90.2423 167.292 90.2245 167.56L69.3528 179.601L64.2796 176.68L58.8713 183.76L67.3499 189.803C68.0349 190.292 68.8597 190.544 69.7006 190.524C70.5415 190.503 71.353 190.211 72.0134 189.69L93.5357 172.711C94.507 173.07 95.5648 173.126 96.5683 172.869C97.5718 172.613 98.4734 172.057 99.1534 171.276C99.8334 170.495 100.259 169.525 100.375 168.496C100.49 167.466 100.289 166.426 99.7992 165.514C99.3091 164.602 98.5529 163.86 97.631 163.388C96.7091 162.916 95.6654 162.736 94.6386 162.871C93.6118 163.007 92.6506 163.452 91.8829 164.147C91.1152 164.842 90.5773 165.755 90.3408 166.763Z" fill="#FFB7B7"/>
<path d="M68.655 180.171L61.9093 188.914C61.7174 189.162 61.4741 189.367 61.196 189.513C60.9179 189.659 60.6116 189.744 60.2979 189.761C59.9842 189.778 59.6704 189.728 59.378 189.613C59.0856 189.498 58.8215 189.321 58.6035 189.095L50.9669 181.165C49.7 180.175 48.877 178.724 48.6784 177.129C48.4798 175.534 48.9217 173.925 49.9074 172.655C50.8931 171.386 52.342 170.559 53.9366 170.355C55.5311 170.152 57.1411 170.59 58.4137 171.572L67.9916 176.927C68.2658 177.081 68.5037 177.291 68.6891 177.545C68.8745 177.799 69.0031 178.089 69.066 178.397C69.129 178.705 69.1249 179.023 69.0539 179.329C68.983 179.635 68.8469 179.922 68.655 180.171Z" fill="#63B4FF"/>
<path d="M79.1714 313.083L85.1281 313.083L86.5304 290.181L79.1704 290.107L79.1714 313.083Z" fill="#FFB7B7"/>
<path d="M77.652 311.138L89.3827 311.138H89.3832C90.3649 311.138 91.3371 311.331 92.2441 311.707C93.1511 312.083 93.9752 312.633 94.6694 313.327C95.3636 314.022 95.9143 314.846 96.29 315.753C96.6657 316.66 96.859 317.632 96.859 318.614V318.857L77.6523 318.857L77.652 311.138Z" fill="#2F2E41"/>
<path d="M22.3246 313.083L28.2813 313.083L31.1149 290.107L22.3236 290.107L22.3246 313.083Z" fill="#FFB7B7"/>
<path d="M20.8053 311.138L32.536 311.138H32.5365C33.5183 311.138 34.4904 311.331 35.3974 311.707C36.3044 312.083 37.1285 312.633 37.8227 313.327C38.5169 314.022 39.0676 314.846 39.4433 315.753C39.819 316.66 40.0123 317.632 40.0123 318.614V318.857L20.8057 318.857L20.8053 311.138Z" fill="#2F2E41"/>
<path d="M28.8167 301.151L21.4242 300.186C21.1153 300.146 20.8186 300.04 20.554 299.876C20.2893 299.712 20.0629 299.493 19.8897 299.234C19.7166 298.975 19.6008 298.682 19.5501 298.374C19.4994 298.067 19.515 297.752 19.5958 297.452L30.3791 244.462C31.4511 239.318 32.1928 234.11 32.5997 228.871C32.9145 224.608 34.1624 218.58 38.3195 214.073C40.01 212.224 41.5879 210.274 43.0444 208.235L46.3084 203.716L68.0522 200.256L68.0671 200.525C69.0064 217.803 73.4943 234.703 81.2511 250.17C84.7402 257.127 86.743 264.734 87.1318 272.507L88.364 296.534C88.3794 296.838 88.3311 297.143 88.222 297.427C88.113 297.712 87.9458 297.971 87.731 298.187C87.5169 298.404 87.2592 298.574 86.9749 298.685C86.6905 298.796 86.3859 298.845 86.0812 298.829L79.0143 298.508C78.5352 298.486 78.0768 298.307 77.7093 297.999C77.3419 297.69 77.0857 297.27 76.9801 296.802L71.0798 270.478C71.0268 270.243 70.9249 270.022 70.7809 269.83L52.2641 245.141C52.1321 244.965 51.9546 244.828 51.7508 244.745C51.5469 244.662 51.3243 244.636 51.1068 244.669C50.8892 244.703 50.6849 244.795 50.5157 244.936C50.3464 245.076 50.2186 245.26 50.1458 245.468L31.1631 299.704C31.0118 300.132 30.732 300.502 30.3621 300.764C29.9921 301.026 29.5501 301.168 29.0967 301.169C29.0031 301.169 28.9095 301.163 28.8167 301.151Z" fill="#2F2E41"/>
<path d="M38.4295 143.935C39.3774 141.061 40.0771 138.112 40.521 135.118C40.6605 133.376 40.2141 131.501 38.9451 130.299C36.5701 128.048 32.535 129.12 30.0513 131.25C26.4619 134.327 24.6717 139.057 23.9951 143.737C23.3186 148.416 23.6162 153.175 23.4534 157.9C23.2905 162.626 22.6147 167.484 20.2056 171.553C17.7965 175.621 13.3259 178.744 8.6104 178.398C6.70706 178.295 4.89977 177.529 3.5021 176.233C2.81602 175.58 2.29644 174.773 1.98691 173.878C1.67739 172.983 1.58694 172.027 1.72316 171.09C-0.686993 175.405 0.534763 181.255 4.12972 184.647C7.72467 188.04 13.2544 188.999 17.9805 187.55C22.7066 186.102 26.5963 182.47 28.9197 178.107C31.243 173.744 32.0944 168.704 31.971 163.762C31.8763 159.967 31.2312 156.142 31.8725 152.4C32.5138 148.659 34.8553 144.86 38.5475 143.979L38.4295 143.935Z" fill="#2F2E41"/>
<path d="M55.0104 148.854C55.6399 150.957 58.0478 152.253 60.2139 151.898C62.38 151.542 64.1612 149.758 64.8194 147.664C65.4144 145.525 65.1708 143.24 64.1383 141.275C63.5693 140.097 62.7736 139.042 61.7967 138.172C60.8198 137.302 59.6809 136.633 58.4452 136.204C57.2024 135.792 55.879 135.685 54.5862 135.891C53.2935 136.098 52.0693 136.612 51.0168 137.391C50.9047 136.918 50.6687 136.484 50.3332 136.133C49.9976 135.782 49.5746 135.527 49.1077 135.394C48.1674 135.141 47.175 135.155 46.2427 135.436C43.5561 136.202 41.2281 137.896 39.6726 140.216C38.1369 142.529 37.121 145.148 36.6949 147.892C35.9618 152.006 36.2322 156.503 38.5431 159.985C40.854 163.467 45.5818 165.524 49.4173 163.863C49.6565 162.641 49.0899 161.41 48.4345 160.35C47.779 159.291 47.0021 158.259 46.7503 157.038C46.4985 155.818 46.9812 154.326 48.1615 153.927C49.1683 153.587 50.2418 154.138 51.3025 154.205C51.9049 154.242 52.5067 154.121 53.0474 153.853C53.5881 153.585 54.0488 153.179 54.3833 152.677C54.7178 152.174 54.9143 151.593 54.953 150.991C54.9918 150.388 54.8715 149.786 54.6042 149.245L55.0104 148.854Z" fill="#2F2E41"/>
<path d="M138.194 140.287C138.076 140.269 137.957 140.258 137.837 140.251C139.194 143.375 139.288 146.903 138.1 150.096C138.219 150.082 138.337 150.063 138.455 150.041C139.605 149.818 140.639 149.194 141.373 148.281C142.106 147.368 142.492 146.224 142.461 145.053C142.429 143.883 141.983 142.761 141.202 141.888C140.421 141.015 139.355 140.448 138.194 140.287Z" fill="#3F3D56"/>
<ellipse cx="125.51" cy="145.5" rx="13.51" ry="13.5" fill="#93C5FD"/>
<path d="M113.625 140.251C113.506 140.258 113.386 140.269 113.268 140.287C112.108 140.448 111.042 141.015 110.26 141.888C109.479 142.761 109.033 143.883 109.002 145.053C108.97 146.224 109.356 147.368 110.089 148.281C110.823 149.194 111.857 149.818 113.008 150.041C113.125 150.063 113.243 150.082 113.363 150.096C112.174 146.903 112.268 143.375 113.625 140.251Z" fill="#3F3D56"/>
<path d="M138.194 140.287C136.9 137.197 134.502 134.699 131.468 133.277C128.434 131.855 124.979 131.611 121.774 132.593C118.57 133.574 115.845 135.711 114.129 138.587C112.413 141.464 111.828 144.876 112.488 148.159C113.149 151.443 115.006 154.364 117.701 156.355C120.396 158.346 123.735 159.265 127.07 158.934C130.405 158.602 133.498 157.043 135.747 154.561C137.997 152.078 139.242 148.848 139.242 145.499C139.241 143.709 138.885 141.938 138.194 140.287ZM138.1 150.096C136.95 153.183 134.687 155.728 131.754 157.231C128.821 158.734 125.431 159.085 122.252 158.215C119.073 157.346 116.335 155.319 114.576 152.533C112.818 149.748 112.166 146.405 112.75 143.163C113.334 139.922 115.111 137.016 117.731 135.018C120.351 133.02 123.625 132.074 126.908 132.367C130.191 132.66 133.245 134.17 135.469 136.6C137.694 139.031 138.928 142.205 138.928 145.499C138.927 147.068 138.647 148.625 138.1 150.096Z" fill="#3F3D56"/>
<path d="M132.644 142.438H118.819C118.09 142.438 117.39 142.727 116.875 143.242C116.359 143.757 116.069 144.456 116.069 145.185C116.069 145.914 116.359 146.612 116.875 147.128C117.39 147.643 118.09 147.932 118.819 147.932H132.644C133.373 147.932 134.072 147.643 134.588 147.128C135.103 146.612 135.393 145.914 135.393 145.185C135.393 144.456 135.103 143.757 134.588 143.242C134.072 142.727 133.373 142.438 132.644 142.438Z" fill="#3F3D56"/>
<path d="M334 4V306" stroke="#CBD5E1" stroke-width="7" stroke-linecap="round"/>
<path d="M334 4V115" stroke="#94A3B8" stroke-width="7" stroke-linecap="round"/>
</svg>

</template>