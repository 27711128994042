<template>
    <svg width="136" height="141" viewBox="0 0 136 141" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M60.4359 122.815C46.5825 122.821 33.1477 118.066 22.3832 109.346C11.6186 100.626 4.17849 88.4703 1.30959 74.9172C-1.55931 61.364 0.317381 47.2366 6.62518 34.9025C12.933 22.5684 23.2886 12.7772 35.9564 7.16977C48.6243 1.56232 62.8346 0.479401 76.2058 4.1025C89.5771 7.7256 101.297 15.8345 109.401 27.0704C117.505 38.3062 121.501 51.986 120.719 65.8174C119.937 79.6488 114.424 92.7911 105.105 103.042L117.72 117.183L111.425 119.853C101.687 123.984 92.157 119.965 86.8081 116.757C78.5877 120.742 69.5714 122.813 60.4359 122.815ZM60.4359 11.9289C52.7319 11.9313 45.1303 13.6948 38.2121 17.0847C31.2939 20.4745 25.2422 25.4011 20.5195 31.4878C15.7967 37.5745 12.5279 44.6603 10.9628 52.2037C9.39765 59.7471 9.57771 67.5484 11.4891 75.0116C13.4006 82.4748 16.9928 89.4022 21.9913 95.2646C26.9898 101.127 33.2624 105.769 40.3296 108.836C47.3968 111.903 55.0716 113.314 62.7675 112.961C70.4635 112.608 77.9769 110.501 84.7337 106.799L87.4285 105.319L89.9341 107.101C92.7974 109.184 96.0488 110.671 99.4965 111.476L91.5584 102.578L95.038 99.3081C100.092 94.5998 104.118 88.8966 106.861 82.5572C109.605 76.2177 111.006 69.3792 110.979 62.4716C110.963 49.0715 105.634 36.2246 96.1582 26.7493C86.6829 17.274 73.836 11.9441 60.4359 11.9289Z" fill="#E6E6E6"/>
<path d="M115.879 62.4716C115.874 50.3252 111.879 38.5167 104.508 28.8624C97.1377 19.208 86.7995 12.2424 75.0837 9.03694C63.3679 5.83145 50.9235 6.5636 39.6644 11.1208C28.4053 15.6779 18.9552 23.8077 12.7674 34.2598C6.57956 44.7119 3.99681 56.9073 5.41636 68.9704C6.83591 81.0336 12.1791 92.2963 20.6242 101.026C29.0693 109.757 40.1486 115.471 52.158 117.29C64.1674 119.109 76.4418 116.932 87.0934 111.094C90.9259 113.821 100.276 119.26 109.512 115.342L98.3938 102.879C103.921 97.7008 108.325 91.442 111.332 84.491C114.34 77.5401 115.888 70.0453 115.879 62.4716Z" fill="white"/>
<path d="M71.498 51.1355H31.8809V54.8145H71.498V51.1355Z" fill="#93C5FD"/>
<path d="M100.93 63.8624H31.8809V67.5414H100.93V63.8624Z" fill="#E6E6E6"/>
<path d="M100.863 76.5893H31.8809V80.2683H100.863V76.5893Z" fill="#E6E6E6"/>
<path d="M127.36 31.5395C128.597 16.5945 117.484 3.4765 102.539 2.23961C87.5945 1.00273 74.4765 12.1153 73.2396 27.0603C72.0027 42.0053 83.1153 55.1233 98.0603 56.3602C113.005 57.5971 126.123 46.4845 127.36 31.5395Z" fill="#93C5FD"/>
<path d="M107.432 17.9863L100.3 25.1188L93.1673 17.9863C92.0127 16.8317 90.1408 16.8317 88.9862 17.9863C87.8316 19.1409 87.8316 21.0128 88.9862 22.1674L96.1187 29.3L88.9862 36.4325C87.8327 37.5882 87.8344 39.4601 88.9901 40.6136C90.1442 41.7656 92.0132 41.7656 93.1673 40.6136L100.3 33.4811L107.432 40.6136C108.588 41.7671 110.46 41.7654 111.614 40.6097C112.766 39.4556 112.766 37.5866 111.614 36.4325L104.481 29.3L111.614 22.1674C112.768 21.0128 112.768 19.1409 111.614 17.9863C110.459 16.8317 108.587 16.8317 107.432 17.9863Z" fill="white"/>
<path d="M126.341 141C131.167 141 135.08 137.087 135.08 132.261C135.08 127.434 131.167 123.522 126.341 123.522C121.514 123.522 117.601 127.434 117.601 132.261C117.601 137.087 121.514 141 126.341 141Z" fill="#93C5FD"/>
</svg>

</template>